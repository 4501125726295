@use "../assets/colors" as colors;

.budget {
  .content {
    background-color: colors.$white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 6.5rem;
  }

  .item {
    width: 50%;
    display: flex;
    padding: 0 2.5rem 0 2.5rem;
    align-items: center;
  }

  .itemFull {
    width: 100%;
    display: flex;
    padding: 2.5rem;
    align-items: center;
  }

  .itemBreakLine {
    width: 100%;
    display: flex;
    height: 1px;
    background-color: colors.$black;
    margin-top: 1rem;
    margin-bottom: 3.5rem;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1px;
    color: colors.$ultramarine;
  }

  img {
    margin-right: 0.5rem;
  }

  span {
    color: colors.$bright-blue;
  }
  form {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
    label {
      display: flex;
      position: relative;
      justify-content: center;
      span {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 40%;
        font-size: 1.25rem;
        color: #334551;
        line-height: 4.0625rem;
      }
    }
  }
  input {
    height: 4.0625rem;
    width: 9.35rem;
    -webkit-appearance: none;
    appearance: none;
    border: 0 none;
    padding: 0.9375rem 1.25rem 0.9375rem 50%;
    background-color: colors.$white;
    font-family: inherit;
    border: 2px solid;
    border-color: #ebeef1;
    border-radius: 0.75rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.4375rem;
    color: #334551;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  button {
    appearance: none;
    background-color: transparent;
    border: 0.125rem solid colors.$white;
    background-color: colors.$blue-bolt;
    width: 14.25rem;
    height: 4.0625rem;
    font-family: inherit;
    border-radius: 0.75rem;
    padding: 1rem 2.25rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.4375rem;
    text-align: center;
    color: colors.$white;
    margin-left: 2rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .marginLeft5 {
    margin-left: 5px;
  }
  @media only screen and (max-width: 800px) {
    .content {
      display: block;
      width: 100%;
    }
    .hidden {
      display: none;
    }
    .item {
      width: 100% !important;
      padding: 0rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    .itemFull {
      width: 100% !important;
      padding: 0rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .itemBreakLine {
      width: 100%;
      background-color: colors.$black;
      margin: 0;
    }
    form {
      display: block;
      justify-content: center;
      width: 100%;
      label span {
        left: 45%;
      }
    }
    input {
      width: 100%;
    }
    button {
      width: 100%;
      margin: 0;
      margin-top: 2rem;
    }
  }
}
