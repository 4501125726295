$black: #000;
$blue: #33B2E1;
$darkBlue: #2895BE;
$navyBlue: #0F66AE;
$green: #BED600;
$orange: #FE9000;
$darkOrange: #EB7325;
$grey: #999999;
$lightGrey: #F1F1F1;
$charcoalGrey: #444444;
$white: #FFFFFF;
$olive: #879327;
$yellow: #F4CA00;
$red: #D7253B;
$background: #FFFFFF;
$text: #606e78;
$title: #000;
$consumption: #33B2E1;
$supply: #2895BE;
$generation: #BED600;
$average: #182663;
$budget: #FE9000;
$input-border: #dee5ec;
$overview-solar: #F9CF48;
$azure: #F8F9F9;
$footer-link: #37B5E3;
$light-silver: #D8D8D8;
$bright-blue: #006CB7;
$gold: #FFD600;
$bright-green: #BFD600;
$blue-bolt: #007ACD;
$ultramarine: #182663;
$light-yellow: #FFE975;
$cyan: #00AAFF;
