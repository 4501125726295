@use "../../assets/colors" as colors;

.energySavingTipsBanner {
  background-color: white;
  overflow: hidden;
}

.energySavingTipsLink {
  color: #006cb7;
}

.energySavingTipsButton {
  background: #007acd;
  padding: 1.3rem 2rem;
  color: white;
  border-radius: 6px;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
}

.content {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 2rem;
  background: #f8f9f9;
  border-radius: 2rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;
  flex-direction: column;

  p {
    margin-bottom: 2rem;
  }
}

.item {
  width: 100%;
  display: flex;
  padding: 4rem 4rem 4rem 4rem;
  align-items: center;
}

.itemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2.5rem;
}

.message {
  text-align: left;
  vertical-align: middle;
  padding: 0px 15px 0px 15px;
  width: 100%;
  font-size: 1rem;
  color: #595959;
  h2 {
    color: #182663;
    font-size: 2rem;
    line-height: 3rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }

  .textGreen {
    color: colors.$bright-green;
    font-size: 2.25rem;
  }
}

@media only screen and (max-width: 800px) {
  .content {
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 32px;
    margin-right: 32px;
  }

  .item {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .itemLeft {
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .itemFullLine {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .itemRight {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .itemLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0rem;
  }
}
