@use "../assets/colors" as colors;

.overview {
  background-color: #f6f8f9;
  overflow: hidden;
}

.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: #17255f;
}

.itemFullLine {
  width: 100%;
  padding: 2.5rem;
  h2 {
    font-size: 2.8125rem;
    line-height: 3rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
  }
}

@media only screen and (max-width: 800px) {
  .content {
    display: block;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .itemFullLine {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
