@use "../../assets/colors" as colors;

.eventChart {
  background-color: colors.$white;
  border-radius: 3px;
  height: auto;
  padding-bottom: 3rem;
}

.chart {
  display: flex;
  justify-content: center;
}

svg {
  height: auto;
}

.ledger {
  display: block;
  margin-left: auto;
  width: 85%;
  box-sizing: border-box;
  padding-top: 30px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  font-size: 1.25rem;
  color: #fff;
  line-height: 100px;
  float: left;
  padding: 10px;
  align-items: center;
  vertical-align: bottom;
}

.orangeCircle {
  background: #fe9000;
}

.blueCircle {
  background: #09aeff;
}

.greenCircle {
  background: #bfd600;
}

.yellowCircle {
  background: #ffe24d;
}

.black-circle {
  background: black;
}

.message {
  float: left;
  width: 90%;
  padding: 20px 10px;
  color: #435c6e;
  font-size: 1.125rem;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.chartNavigation {
  padding-top: 0.5rem;
}

.chartNavigationInner {
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: 5rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 6rem;
  padding-right: 6rem;
  position: relative;
}

.button {
  appearance: none;
  text-decoration: none;
  background-color: transparent;
  border: 0 none;
  height: 3rem;
  font-family: inherit;
  font-size: 0.875rem;
  color: colors.$title;
  line-height: 2rem;
  position: relative;
  overflow: hidden;
  padding: 0.5rem;
}

.button.buttonPrevious {
  display: block;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  transform: translateY(-1.5rem);
  position: absolute;
  top: 50%;
  left: 3rem;
}

.button.buttonNext {
  display: block;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  transform: translateY(-1.5rem);
  position: absolute;
  top: 50%;
  right: 3rem;
}

.buttonClicker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonHiddenLabel {
  position: absolute;
  top: 200%;
}

.buttonClicker img {
  margin: auto auto;
}

.chartDate {
  font-size: 1.25rem;
  font-weight: 400;
  color: #006cb7;
}
