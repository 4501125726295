@use "../assets/colors" as colors;

.item {
  width: 50%;
  display: flex;
  padding: 2.5rem;
}

.itemBreakLine {
  width: 100%;
  display: flex;
  height: 0;
  background-color: black;
  margin: 0 2.5rem;
}

.itemRight {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.itemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  h5 {
    font-size: 2.25rem;
    line-height: 2.828125rem;
    font-weight: bold;
  }
  span {
    font-weight: normal;
    font-size: 0.875rem;
    word-break: break-word;
    font-size: 1.125rem;
    line-height: 1.41375rem;
  }
}
.title {
  color: colors.$blue-bolt;
}

@media only screen and (max-width: 800px) {
  .item {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .itemBreakLine {
    display: none;
  }
  .itemRight {
    display: none;
  }
  .itemLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0rem;
  }
}
