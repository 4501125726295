@use "../assets/colors" as colors;
.form {
  ul,
  ol {
    padding: 0;
    margin-left: 1em;
    list-style-position: inside;
    display: flex;
    flex-direction: column;
  }

  .required {
    color: #b3b3b3;
    font-size: 12px;
    border: 1px solid #ececec;
    border-radius: 10px;
    padding: 2px 8px;
  }

  .surveyQuestion {
    text-align: left;
    padding: 10px 0;
  }

  .surveyQuestion label:hover {
    cursor: pointer;
  }

  .surveyQuestion p {
    font-size: 15px;
    padding: 5px 0;
    display: block;
  }

  .surveyQuestion input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input[type="number"] {
    color: #b3b3b3;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    text-shadow: none;
    border: 1px solid #b3b3b3;
    border-radius: 4px 4px 4px 4px;
    padding: 0px 45px;
    height: 2.5625rem;
    width: 9.35rem;
    font-family: inherit;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .itemFull {
    width: 100%;
    display: flex;
    padding: 10px 0px 20px 0px;
    align-items: center;
  }

  .itemFull label {
    display: flex;
    position: relative;
    justify-content: left;
  }

  .kw {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 3%;
    font-size: 14px;
    font-weight: bold;
    color: #b3b3b3;
    line-height: 2.5625rem;
  }

  .surveyQuestion label {
    float: left;
    display: inline-block;
    background-color: #ffffff;
    color: #b3b3b3;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-shadow: none;
    margin: 10px 0;
    padding: 10px 20px;
    border: 1px solid #b3b3b3;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  .surveyQuestion input:checked + label {
    background-color: #d7eff5;
    color: #0f466c;
    border: 1px solid #2ca8dc;
  }

  .surveyQuestion label:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  .surveyQuestion label:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  .banner {
    display: block;
    text-align: left;
    background-color: #dbeef4;
    padding: 25px;
    margin-bottom: 20px;
    position: relative;
  }

  .banner h2 {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #1f4569;
    padding: 10px 0;
    position: relative;
  }

  .banner .skip {
    background: transparent;
    border: transparent;
    position: absolute;
    font-size: 16px;
    font-weight:700;
    color: #1f4569;
    top: 10px;
    right: 0;
    cursor: pointer;
    text-decoration: underline;
  }

  .banner p {
    font-size: 16px;
  }

  .submit {
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0.125rem solid colors.$white;
    background-color: colors.$bright-blue;
    width: 5.25rem;
    height: 2.0625rem;
    font-family: inherit;
    border-radius: 0.375rem;
    font-style: normal;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    text-align: center;
    cursor: pointer;
    color: colors.$white;
    margin-left: 15px;
  }
}
