@use "../assets/colors" as colors;

.chart {
  height: 100%;

  .chartContainer {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 5rem;
  }

  .chartMessage,
  .chartHint {
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    transform: translate(-50%, 0);
    text-align: center;
  }

  .chartMessage {
    background-color: colors.$lightGrey;
    color: colors.$grey;
  }

  .chartHint {
    background-color: colors.$charcoalGrey;
    color: colors.$white;
  }

  @media only screen and (orientation: portrait) {
    .chartContainer {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 0 1rem;
    }
  }

  @media only screen and (orientation: landscape) {
    .chartContainer {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 0 5rem;
    }
  }
}
