@use "../../assets/colors" as colors;

.rewardsOverview {
  background-color: #ffffff;
  overflow: hidden;
}
.rank {
  
  margin: 0 2rem !important;
  border-radius: 40px;
  padding: 2.5rem;
}
.gold {
  background: #02acfd;
}
.silver {
  background: #E6F2FA;
}
.bronze {
  background: #EBEEF1
}
.missing {
  background: #fff;
}
.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.itemFullLine {
  width: 100%;
  padding: 2.5rem;
  h2 {
    font-size: 1.5rem;
    line-height: 3rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 1rem;
    color: #000000;
  }
}

.items {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  .items-center {
    margin: 0 3rem;
  }
}

.itemsCenter {
  display: flex;
  justify-content: center;
  margin: 0 2rem;
}

.item {
  width: 50%;
  display: flex;
  padding: 2.5rem;
}

.itemRight,
.currencySymbol {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.itemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  h5 {
    font-size: 2.25rem;
    line-height: 2.828125rem;
    font-weight: bold;
  }
  span {
    font-weight: normal;
    font-size: 0.875rem;
    word-break: break-word;
    font-size: 1.125rem;
    line-height: 1.41375rem;
  }
}
.text { font-size: 1.125rem; color: #435c6e; }
.textBlue {
  color: #182663;
}
.totalBillCreditsEarned {
  background: #D7F0F9;
  border-radius: 40px;
}
@media only screen and (max-width: 800px) {
  .content {
    display: block;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .rank {
    margin: 0 !important;
  }
  .totalBillCreditsEarned {
    margin: 0;
    padding: 1rem !important;
  }
  .items {
    flex-direction: column;
    text-align: center;
  }
  .items > .itemsCenter {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .item {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .itemFullLine {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .itemLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .currencySymbol {
    padding-right: 2rem;
  }
}
