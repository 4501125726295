@use "../assets/colors" as colors;

#legend-total_spend rect {
  fill: colors.$cyan;
}
#legend-total_spend_circle circle {
  fill: colors.$cyan;
}
#legend-solar_input rect {
  fill: colors.$light-yellow;
}
#legend-solar_input_circle circle {
  fill: colors.$light-yellow;
}
#legend-service_charge rect {
  fill: colors.$blue-bolt;
}
#legend-service_charge_circle circle {
  fill: colors.$blue-bolt;
}
#legend-average_spend circle {
  fill: colors.$average;
}
#legend-budget rect {
  fill: colors.$orange;
}
#legend-budget_circle circle {
  fill: colors.$orange;
}
