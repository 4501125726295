@use "../assets/colors" as colors;

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loading {
  position: fixed;
  z-index: 100;
  top: -50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  opacity: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: colors.$blue;
}
@media only screen and (min-width: 640px) {
  .loading {
    width: 4rem;
    height: 4rem;
  }
}
.loading .circleAnimation {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  border: 3px solid colors.$white;
  border-top-color: colors.$grey;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loading.isLoading {
  top: 50%;
  transform: translateY(0%);
  opacity: 0.999;
}
