@use "../../assets/colors" as colors;
@import "../../assets/common";

.daytimeSaverLanding {
  .navigation {
    background-color: colors.$azure;
  }

  .header {
    background-color: colors.$azure;
    text-align: center;
    padding: 3rem;

    @include md-down {
      padding: 2rem;
    }

    .stepContainer {
      display: flex;
      justify-content: space-around;
      margin: 0 12%;
      margin-top: 4rem;

      @include lg-down {
        margin: 0 0%;
        margin-top: 2rem;
      }

      @include sm-down {
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
      }

      .step {
        width: 22%;
        margin: 1rem 0.5rem;
        @include text_2;

        img {
          margin-bottom: 1.5rem;
        }

        h4 {
          font-weight: 700;
        }

        @include sm-down {
          width: 100%;
        }
      }
    }
  }

  &Container {
    background-color: colors.$white;
    padding: 0 4rem;

    @include md-down {
      padding: 0rem 2rem;
    }

    .heading {
      @include heading_2;
    }

    .title {
      @include heading_1;
    }

    .timeslotSection {
      padding: 5rem 3rem;

      @include md-down {
        padding: 4rem 0rem;
      }

      .info {
        font-weight: 300;
        font-size: 1rem;
      }

      .timeslots {
        padding: 2rem 0;
        display: flex;
        flex-wrap: wrap;

        @include sm-down {
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    .termConditionSection {
      .heading {
        padding: 1.75rem 3rem;

        @include md-down {
          padding: 1.75rem 0rem;
        }
      }

      .content {
        @include text_2;
        max-height: 420px;
        margin: 1.5rem 4%;
        overflow-y: scroll;
        padding-right: 1rem;

        &::-webkit-scrollbar {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #d8d8d8;
          border-radius: 100px;
        }
      }

      .decorationLine,
      .decorationLineTop,
      .decorationLineBottom {
        margin: 1.25rem 0;
        height: 1.5px;
        background: #d8d8d8;
        border-radius: 1px;
      }

      .decorationLineTop {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      }

      .decorationLineBottom {
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.2);
      }

      .checkbox {
        margin: 2.3rem 3rem;
        display: flex;
        vertical-align: middle;
        align-items: center;

        @include md-down {
          margin: 2.3rem 1rem;
        }

        input[type="checkbox"] {
          width: 24px;
          height: 24px;
          border: 2px solid #e5e5e5;
          border-radius: 6px;
          margin-right: 0.75rem;
        }

        h4 {
          font-weight: 700;
          font-size: 1rem;
        }
      }
    }

    .btnContainer {
      padding: 3rem;

      @include sm-down {
        padding: 3rem 0;
      }

      button {
        background: #3b6cac;
        border-radius: 10px;
        border: none;
        padding: 1.5rem 4.5rem;
        margin: 2rem 0;
        font-weight: 400;
        font-size: 1.125rem;
        color: colors.$white;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          opacity: 0.5;

          &:hover {
            cursor: auto;
          }
        }
      }
    }
  }
}
