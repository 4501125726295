@use "../../assets/colors" as colors;
@import "../../assets/responsive";
@import "../../assets/typography";

.optBackInContainer {
  background-color: colors.$white;

  .right {
    padding: 2rem 7rem;
    .title {
      margin-bottom: 3rem;
    }
  }
  .rightSubText {
    padding: 2rem 7rem;
    font-style: italic;
  }
  .btnContainer {
    padding: 0rem 7rem;
    button {
      background: #3b6cac;
      border-radius: 10px;
      border: none;
      padding: 1.5rem 4.5rem;
      margin: 2rem 0;
      font-weight: 400;
      font-size: 1.125rem;
      color: colors.$white;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
