@use "../../assets/colors" as colors;
@import "../../assets/common";

.daytimeSaverConfirmation {
  background-color: colors.$white;

  .title {
    padding: 4rem 1rem 2.5rem;
    text-align: center;
    @include heading_1;
  }

  .content {
    margin: 0 25%;
    text-align: center;

    p {
      @include text_1;
      margin-bottom: 2rem;

      &.secondParagraph {
        padding: 0 2rem;
      }

      &.lastParagraph {
        margin: 0 9%;
      }
    }

    .timeslot {
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;

      button {
        font-weight: 700;
      }
    }
  }

  .contentBottom {
    margin-left: 1rem;

    img {
      width: 25rem;
    }
  }

  @include md-down {
    .content {
      margin: 0 1rem;

      p.lastParagraph {
        margin: 0;
      }
    }

    .contentBottom {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }

  @include sm-down {
    .contentBottom {
      img {
        width: 100%;
      }
    }
  }
}
