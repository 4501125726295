@use "../assets/colors" as colors;

.navigation {
  display: block;
  background-color: colors.$white;
  position: relative;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;

  .navigationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  .navigationLinkContainers {
    height: 0px;
    overflow: hidden;
  }
  .navigationLinkContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
  }
  .navigationLinks {
    display: flex;
  }
  .navigationLinks > .navigationLink {
    margin-left: 1rem;
  }
  .navigationLink {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    text-decoration: none;
    color: colors.$ultramarine;
    font-weight: 700;
    letter-spacing: 0.07em;
  }

  .navigationLink img {
    margin-right: 0.5rem;
  }
  .toggleButton {
    margin-right: 2.5rem;
    display: none;
  }
  .toggleButton button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  a {
    display: block;
    margin-left: 2.5rem;
  }
  .logo {
    display: block;
  }
  @media only screen and (max-width: 768px) {
    a {
      margin-left: 0.5rem !important;
    }
    .hidden {
      display: none;
    }
    .toggleButton {
      margin-right: 0rem;
      display: block;
    }
    .navigationLinks {
      display: none;
    }
    .navigationLinkContainers.active {
      height: 112px;
    }
    .navigationHeader {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
