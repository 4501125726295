@use "../assets/colors" as colors;

.alert {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 300ms ease;

  &.alert_active {
    transform: translateY(0%);
    opacity: 0.999;
  }

  &.alert_message {
    .body {
      border-color: colors.$grey;
      color: colors.$grey;
    }
  }

  &.alert_warning {
    .body {
      border-color: colors.$orange;
      color: colors.$orange;
    }
  }

  &.alert_error {
    .body {
      border-color: colors.$red;
      color: colors.$red;
    }
  }

  .body {
    background-color: colors.$white;
    border: 0.125rem solid;
    padding: 0.6rem 3.5rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 auto 0 auto;
    max-width: 28rem;
    position: relative;
    min-height: 3rem;
  }
  button {
    appearance: none;
    border: 0 none;
    background-color: transparent;
    padding: 0;
    width: 3rem;
    height: 3rem;
    line-height: 1rem;
    color: inherit;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0rem;
    font-size: 1.5rem;
  }

  button {
    .label {
      position: absolute;
      top: -20rem;
    }
  }

  button {
    .icon {
      position: relative;
      top: -0.125rem;
    }
  }
}
