@use "../assets/colors" as colors;
/* COLUMN STYLING */

/* COLUMN STYLING */
.chartColumn {
  height: 100%;
  position: relative;
  flex: 1;
  text-align: center;
  font-family: inherit;
  color: colors.$charcoalGrey;
  font-size: 0.75rem;
  border-left: 0.0625rem solid colors.$background;
  border-right: 0.0625rem solid colors.$background;
  font-weight: 400;
}
@media only screen and (min-width: 640px) and (orientation: landscape) {
  .chartColumn {
    font-size: 0.875rem;
  }
}
.chartColumn:hover {
  background-color: colors.$lightGrey;
  border-radius: 1rem;
}
.chartColumn:hover div.labelTotal,
.chartColumn:hover div.labelTime {
  font-weight: bold;
}

/* CHART */
.chart {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
.chartWrap {
  position: absolute;
  top: 4.5rem;
  bottom: 0.5rem;
  left: 0;
  right: 0;
}

/* SVG */
.chartImage {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}
.chartImage {
  overflow: visible;
}
.chartImage .totalSpend {
  fill: colors.$cyan;
}
.chartImage .gridSpend {
  fill: colors.$cyan;
}
.chartImage .serviceCharge {
  fill: colors.$blue-bolt;
}
.chartImage .solarInput {
  fill: colors.$light-yellow;
}
.chartImage .budget {
  stroke: colors.$budget;
  stroke-width: 1px;
}
.chartImage .average {
  fill: colors.$average;
}

/* LABELS ELEMENT */
.labels {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.chartColumnWeekView .labels {
  cursor: pointer;
}
.labels.hover + .overlay {
  display: block;
}
.chartColumnWeekView .average {
  stroke-width: 1;
}
@media only screen and (min-height: 200px) {
  .chartColumnWeekView .average {
    stroke-width: 3;
  }
}
.chartColumnDayView .average {
  stroke-width: 1;
}
@media only screen and (min-height: 200px) {
  .chartColumnDayView .average {
    stroke-width: 0;
  }
}

/* LABELS */
.label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 5rem;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}
.labelTotal {
  top: 0;
  font-style: normal;
  font-weight: bold;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.875rem;
}
.labelNoData {
  width: 100%;
}
.labelTime {
  top: 2.5rem;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
}
.labelTimeDayView {
  top: -2.5rem;
}
.labelValue {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 5rem;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
  top: 5rem;
}

/* LABEL VARIATIONS */
.chartColumnDayView .labelTotal {
  display: none;
}
.chartColumnDayView.chartColumnIsMax .labelTotal {
  background: none;
  color: colors.$charcoalGrey;
  display: flex;
}
.chartColumnDayView:hover .labelTotal {
  background: colors.$lightGrey;
  display: flex;
  z-index: 2;
  padding-left: 1rem;
  padding-right: 1rem;
}
.chartColumnDayView .labelTime {
  display: none;
}
.chartColumnDayView .labelTime.labelMod3 {
  display: flex;
}

/* TIME */
.timeMinutes {
  display: none;
}
.timeDay,
.timeDate {
  width: 100%;
  margin: 0 0.125rem;
}
@media only screen and (min-width: 480px) {
  .timeMinutes {
    display: block;
  }
  .timeDay,
  .timeDate {
    width: auto;
  }
}

/* OVERLAY */
.overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.125rem;
  right: -0.125rem;
  z-index: 3;
  border: 0.1rem solid white;
  transition: all 200ms ease;
  background-color: colors.$azure;
  text-decoration: none;
  border-radius: 1rem;
  box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.1);
}
.overlay .button {
  text-decoration: none;
}
.overlay .value {
  padding: 0.5rem 0;
  color: colors.$charcoalGrey;
  position: relative;
  text-align: right;
  display: none;
  width: 100%;
  align-content: space-between;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  font-style: normal;
  font-weight: 300;
}
.overlay .value span {
  margin-left: 0.5rem;
}
@media only screen and (min-width: 480px) {
  .overlay .value {
    display: flex;
    align-items: center;
  }
}
.overlay .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 480px) {
  .overlay .icon {
    top: auto;
    bottom: 1rem;
  }
}
.overlay .icon circle,
.overlay .icon line {
  stroke: colors.$budget;
  fill: colors.$white;
  stroke-linecap: round;
  stroke-width: 2;
}

/* NO DATA */
.noData {
  background-color: colors.$lightGrey;
  position: absolute;
  top: 2rem;
  bottom: 2rem;
  left: 0;
  right: 0;
}
.noData span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  display: block;
  transform: translate(-50%, -50%);
  color: colors.$grey;
}
.dayView {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  margin-bottom: 1rem;
  width: 100%;
}
.dayView {
  padding: 0 1.125rem;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0.125rem solid colors.$white;
  background-color: colors.$blue-bolt;
  height: 4.0625rem;
  width: 100%;
  font-family: inherit;
  border-radius: 0.75rem;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem !important;
  text-align: center;
  vertical-align: middle;
  color: colors.$white;
  cursor: pointer;
}
.button:hover {
  border: 0.125rem solid colors.$white;
}
.button:focus {
  outline: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .labelTotal {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .labelTime {
    font-size: 0.65rem;
    line-height: 0.75rem;
  }
  .dayView {
    margin-bottom: 0;
  }
  .dayView {
    padding: 0;
  }
  .button {
    height: 2.5rem;
    font-size: 0.5rem !important;
  }
  .overlay .value {
    margin-top: 0.5rem;
    font-size: 0.7rem;
    line-height: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .labeTtotal {
    font-size: 0.9rem;
    line-height: 1.25rem;
  }
  .labelTime {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .dayView {
    margin-bottom: 0.25rem;
  }
  .dayView {
    padding: 0 0.25rem;
  }
  .button {
    height: 2.75rem;
    font-size: 0.75rem !important;
  }
  .overlay .value {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .label-total {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  .label-time {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .day-view {
    margin-bottom: 0.25rem;
  }
  .day-view a {
    padding: 0 0.25rem;
  }
  .button {
    height: 3.5rem;
    font-size: 1rem !important;
  }
  .overlay .value {
    font-size: 0.9rem;
    line-height: 1.25rem;
    margin-top: 1.25rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .labelTotal {
    font-size: 0.9rem;
    line-height: 1.875rem;
  }
  .labelTime {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .dayView {
    margin-bottom: 1rem;
  }
  .dayView {
    padding: 0 1rem;
  }
  .button {
    height: 4.0625rem;
    font-size: 1.125rem;
  }
  .overlay .value {
    font-size: 0.9rem;
    line-height: 1.75rem;
    margin-top: 1.5rem;
  }
}
