@use "../../assets/colors" as colors;

.rewardsStructure {
  background-color: #ffffff;
  overflow: hidden;
}

.rewardItem {
  padding: 1rem 2rem;
  background: #d7f0f9;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  h4 {
    font-size: 1.875rem;
    font-weight: bold;
    color: #182663;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1rem;
    color: #182663;
    font-weight: 500;
  }
  h6 {
    font-size: 0.875rem;
    color: #435c6e;
    font-weight: 400;
  }
  .rewardIcon {
    padding-right: 1.5rem;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  .heading {
    margin-bottom: 3rem;
  }
}

.rewardItems {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.itemFullLine {
  width: 100%;
  padding: 2.5rem;

  h2 {
    font-size: 2.25rem;
    line-height: 3rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 2.5rem;
    color: #182663;
  }
}

.diamond {
  background: rgba(178, 187, 191, 0.6);
}

.gold {
  background: #02acfd;
}

.silver {
  background: #E6F2FA;
}

.bronze {
  background: #fdb547;
}

h2.textCenter {
  margin-bottom: 1rem;
}
h3.textCenter {
  margin-bottom: 2.5rem;
  font-weight: 400;
}

.textCenter {
  text-align: center;
}

.message {
  text-align: center;
  padding: 0px 15px 0px 15px;
  width: 100%;
  font-size: 1.125rem;
}

.item {
  display: flex;
  vertical-align: middle;
  align-items: center;
  padding: 2.5rem;
}

.itemRight {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.itemLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
}

@media only screen and (max-width: 800px) {
  .itemFullLine {
    padding: 2.5rem 0rem;
  }

  .content {
    display: block;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .rewardItems {
    grid-template-columns: repeat(1, 1fr);
  }

  .item {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .rewardItem {
    padding: 1rem;
    flex-direction: column;
    text-align: center;
  }

  .itemRight {
    display: none;
  }

  .itemLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0rem;
  }
}
