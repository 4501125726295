@use "../../assets/colors" as colors;
@import "../../assets/common";

.daytimeSaverChangeTimeslotconfirmation {
  background-color: colors.$white;

  .title {
    padding: 4rem 1rem 2.5rem;
    text-align: center;
    color: #182663;
    font-size: 32px;
  }

  .content {
    margin: 0 25%;
    text-align: center;

    p {
      @include text_1;
      margin-bottom: 2rem;
      color: #435c6e;

      &.last-paragraph {
        margin: 0 9%;
        margin-bottom: 6rem;
      }
    }

    .timeslot {
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;

      button {
        font-weight: 700;
      }
    }

    .lastParagraph {
      margin-bottom: 6rem;
    }
  }

  .contentBottom {
    margin-left: 1rem;

    img {
      width: 25rem;
    }
  }

  @include md-down {
    .content {
      margin: 0 1rem;

      p.lastParagraph {
        margin-bottom: 2rem;
      }
    }

    .contentBottom {
      margin-left: 0rem;
      margin-top: 1rem;
    }
  }

  @include sm-down {
    .contentBottom {
      img {
        width: 100%;
      }
    }
  }
}
