@use "../assets/colors" as colors;

.settings {
  .content {
    background-color: colors.$white;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    color: colors.$text;
    font-weight: 400;
    p {
      padding-bottom: 1rem;
    }

    button {
      appearance: none;
      background-color: transparent;
      background-color: colors.$bright-blue;
      border: 0.125rem solid colors.$white;
      color: colors.$white;
      font-size: 1rem;
      font-family: inherit;
      line-height: 1.5rem;
      padding: 0.25rem 2rem;
      height: 4.0625rem;
      border-radius: 0.375rem;
      cursor: pointer;
    }
    button:focus {
      outline: none;
    }
  }

  h1 {
    background-color: colors.$darkBlue;
    padding: 1rem;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    color: colors.$white;
  }

  h2 {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    color: colors.$text;
  }

  h2 span {
    font-weight: 300;
  }
}

.loading {
  padding: 1rem;
  text-align: center;
  color: colors.$charcoalGrey;
  font-size: 1.3rem;
}