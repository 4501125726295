@use "../assets/colors" as colors;

.usageComponent {
  &.error h2 {
    background-color: colors.$darkBlue;
    padding: 1rem;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    color: colors.$white;
  }
}
