@use "../assets/colors" as colors;

.chartNavigation {
  background-color: colors.$background;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  .chartNavigationInner {
    height: 3rem;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    position: relative;
    h3 {
      display: inline-block;
      margin: 0 0.5rem;
      font-size: 0.875rem;
      font-weight: normal;
      color: colors.$title;
      line-height: 1rem;
    }

    /* BUTTONS */
    .button {
      appearance: none;
      text-decoration: none;
      background-color: transparent;
      border: 0 none;
      height: 3rem;
      font-family: inherit;
      font-size: 0.875rem;
      color: colors.$title;
      line-height: 2rem;
      position: relative;
      overflow: hidden;
      padding: 0.5rem;
    }

    .buttonHiddenLabel {
      position: absolute;
      top: 200%;
    }
    .buttonRoundedLabel {
      color: colors.$title;
      background-color: colors.$lightGrey;
      border-radius: 1rem;
      padding: 0 1rem 0 1.75rem;
      display: inline-block;
    }
    .buttonIcon {
      fill: colors.$title;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .buttonClicker {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .button:hover .buttonIcon {
      fill: colors.$text;
    }

    /* Modifiers */
    .button.buttonNext,
    .button.buttonPrevious {
      display: block;
      width: 3rem;
      height: 3rem;
      overflow: hidden;
      transform: translateY(-1.5rem);
      position: absolute;
      top: 50%;
    }
    .button.buttonNext {
      right: 0;
    }
    .button.buttonPrevious {
      left: 0;
    }
    .button.buttonZoom {
      display: inline-block;
      margin-right: 0.5rem;
    }
    .button.buttonZoom .buttonIcon {
      fill: colors.$text;
      left: 0.5rem;
    }
    .button.buttonZoom:hover .buttonIcon {
      fill: colors.$title;
    }

    /* DATE RANGE */
    .timedateDayShort {
      display: none;
    }
    .timedateDayLong {
      display: none;
    }
    .timedateMonthShort {
      display: inline;
    }
    .timedateMonthLong {
      display: none;
    }
    .timedateYear {
      display: none;
    }
    .weekViewNavigation {
      color: colors.$bright-blue;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
    }
    .hidden {
      display: none !important;
    }
    @media only screen and (max-width: 800px) {
      .display {
        display: block !important;
      }
    }
    @media only screen and (min-width: 480px) {
      .timedateDayShort {
        display: none;
      }
      .timedateDayLong {
        display: inline;
      }
      .timedateYear {
        display: inline;
      }
    }
  }
}
