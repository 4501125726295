@use "../assets/colors" as colors;
@use "../assets/responsive" as responsive;

.footer {
  width: 100%;
  height: 32.125rem;
  background-color: #f6f8f9; // This is a hardcoded color value, not a variable
  position: relative;

  .circles {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .logo {
    max-width: 40%;
    max-height: 40%;
    margin-bottom: 1.5rem !important;
  }

  @media only screen and (max-width: 768px) {
    a {
      margin-left: 0rem !important;
    }

    .logo {
      max-width: 80%;
      max-height: 80%;
      left: 50%;
    }
  }

  .content {
    left: 15%;
    right: 15%;
    margin-top: 5.125rem;
    z-index: 1;
    position: absolute;

    p {
      font-size: 0.75rem;
      line-height: 0.9425rem;
      font-style: normal;
      font-weight: 300;
    }
  }

  &Links {
    display: flex;
    margin-top: 2rem;
    a {
      color: colors.$footer-link;
      font-size: 1rem;
      display: block;
      cursor: pointer;
      text-decoration-line: none;
      &:hover {
        color: colors.$blue;
      }
    }
    span {
      color: colors.$footer-link;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  @media only screen and (max-width: 992px) {
    height: 42.9375rem;
    .content {
      margin-top: 3.6875rem;
    }
    p {
      font-size: 0.75rem;
      line-height: 0.9425rem;
      font-style: normal;
      font-weight: 300;
    }
    &Longer {
      height: 47rem;
    }
  }
  @media only screen and (min-width: 992px) {
    height: 32.125rem;
    .content {
      margin-top: 5.125rem;
    }
    p {
      font-size: 0.75rem;
      line-height: 0.9425rem;
      font-style: normal;
      font-weight: 300;
    }
    &Longer {
      height: 35rem;
    }
  }
}
