@use "../assets/colors" as colors;

.usage {
  background-color: colors.$white;

  button {
    appearance: none;
    background-color: transparent;
    border: 0.125rem solid colors.$white;
    background-color: colors.$blue-bolt;
    width: 9.6875rem;
    height: 4.0625rem;
    font-family: inherit;
    border-radius: 0.75rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.27rem;
    text-align: center;
    color: colors.$white;
    cursor: pointer;
  }
  button:hover {
    border: 0.125rem solid colors.$white;
  }
  button:focus {
    outline: none;
  }
}
.layout {
  background-color: colors.$white;
  position: relative;
  height: 19rem;
  padding-top: 5.5rem;
}
@media only screen and (min-width: 460px) {
  .layout {
    height: 25rem;
  }
}
@media only screen and (min-width: 600px) {
  .layout {
    height: 28rem;
  }
}
@media only screen and (min-width: 768px) {
  .layout {
    height: 35rem;
  }
}
@media only screen and (min-width: 992px) {
  .layout {
    height: 38.125rem;
  }
}
.chartNavigationHeader {
  height: 10.625rem;
  background-color: colors.$white;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  .item {
    width: 50%;
    display: flex;
    padding: 0 2.5rem 0 2.5rem;
    align-items: center;
  }
}
.chartNavigationTitle {
  h2 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1px;
    color: colors.$ultramarine;
  }
}
.chartNavigationInformation {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  img {
    margin-right: 0.5rem;
  }
  span {
    color: colors.$bright-blue;
  }
}
.layoutChartInsights {
  display: none;
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
}
/* STATIC CHART STUFF */
.static {
  padding-top: 4.625rem;
  padding-bottom: 1rem;
}
.information {
  display: flex;
  align-items: center;
  font-size: 1rem;
  span {
    font-size: 1rem;
  }
}

.hidden {
  display: none !important;
}
@media only screen and (max-width: 800px) {
  .chartNavigationInformation {
    display: none !important;
  }
  .itemMobile {
    width: 100% !important;
    padding: 0 !important;
  }
  .chartNavigationHeader {
    height: 5.5625rem;
  }
  .chartNavigationTitle {
    h2 {
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
  }
  .static {
    padding-top: 3rem;
  }
}
