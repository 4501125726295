.termsAndConditions {
  margin-left: 0.5rem;
  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  ol {
    padding-inline-start: 20px;
    counter-reset: list;

    li::marker {
      font-weight: 700;
    }

    &.alphaList {
      > li {
        list-style: none;

        &:before {
          content: counter(list, lower-alpha) ") ";
          counter-increment: list;
        }
      }
    }

    &.customList {
      counter-reset: list;

      > li {
        list-style: none;

        &:before {
          display: none;
        }
      }
    }

    .marker {
      font-weight: 700;
    }
  }
}
