@use "../../assets/colors" as colors;
@import "../../assets/common";

.changeTimeslotContainer {
  font-family: Arial, Helvetica, sans-serif;
  background-color: colors.$white;

  .header {
    padding: 4rem 2rem 0rem;

    .title {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      color: #182663;
      font-size: 32px;
    }

    @include md-down {
      padding: 1rem;
    }
  }

  .timeslots {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include md-down {
      padding: 2rem 1rem;
    }
  }

  .btnContainer {
    padding-bottom: 4rem;
    text-align: center;

    button {
      background: #007acd;
      border-radius: 6px;
      border: none;
      padding: 1.5rem 8.5rem;
      font-weight: 600;
      font-size: 1.25rem;
      color: colors.$white;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        opacity: 0.5;

        &:hover {
          cursor: auto;
        }
      }
    }
  }
}
