@use "../../assets/colors" as colors;

.eventHistory {
  background-color: colors.$white;
  overflow: hidden;
  table {
    text-align: center;
    width: 100%;
  }

  td {
    font-size: 1.125rem;
    height: 87px;
  }

  td {
    padding: 10px 10px;
  }

  th {
    padding: 20px;
    font-weight: 400;
    size: 1.125rem;
  }

  .history {
    background: #F8F9F9;
    border-radius: 1.5rem;
    p {
      padding: 2rem 2.5rem;
      size: 1.1875rem;
    }
  }
}

.historyLink {
  size: 1.125rem;
  color: #007acd;
  font-weight: 400;
}

.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.itemFullLine {
  width: 100%;
  padding: 2.5rem;

  h2 {
    font-size: 1.5rem;
    line-height: 3rem;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }
}
.history {
  width: 100%;
  overflow: auto;
}

.successResult {
  padding: 0.5rem 1rem;
  color: #007acd;
  size: 1.125rem;
}

.failResult {
  padding: 0.5rem 1rem;
  color: #888888;
  size: 1.125rem;
}

.missingUsageResult {
  padding: 0.5rem 1rem;
  color: #888888;
  size: 1.125rem;
}

.ranking {
  padding: 0.5rem 0rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 250px;
}

.bronze {
  background: #fdb547;
}

.silver {
  background: #e5e5e5;
}

.gold {
  background: #fde062;
}

.diamond {
  background: #b2bbbf99;
}

.diamondSmallIcon {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 800px) {
  .content {
    display: block;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .item {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .itemFullLine {
    width: 100% !important;
    padding: 0rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
