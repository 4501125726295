$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

/***
EXAMPLES:

@include sm-down {
  font-size: 0.8rem;
}

@include sm-down {
  padding: 0.8rem;
}
***/

@mixin sm-down {
  @media (max-width: #{$small}) {
    @content;
  }
}

@mixin sm-only {
  @media (min-width: 0) and (max-width: #{$small}) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: #{$medium}) {
    @content;
  }
}

@mixin md-only {
  @media (min-width: #{$small}) and (max-width: #{$medium}) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: #{$large}) {
    @content;
  }
}

@mixin lg-only {
  @media (min-width: #{$medium}) and (max-width: #{$large}) {
    @content;
  }
}

@mixin x-lg-down {
  @media (max-width: #{$x-large}) {
    @content;
  }
}

@mixin x-lg-only {
  @media (min-width: #{$large}) and (max-width: #{$x-large}) {
    @content;
  }
}

@mixin xx-large-down {
  @media (min-width: #{$xx-large}) {
    @content;
  }
}

@mixin xx-large-only {
  @media (min-width: #{$x-large}) and (max-width: #{$xx-large}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
