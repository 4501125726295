/* Light reset */

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a img {
  outline: 0 none;
  border-width: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #F8F8F8;

  #app {
  max-width: 78.3125rem; /* 1253px */
  margin-left: auto;
  margin-right: auto;
}
}
