@use "../assets/colors" as colors;

@media only screen and (max-width: 800px) {
  .chartNavigation {
    padding: 0;
  }
}
.chartNavigation {
  background-color: colors.$background;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
