@use "../assets/colors" as colors;

.staticChartLegend {
  margin-bottom: 1rem;
}
.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;

  @media only screen and (min-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media only screen and (min-width: 992px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  &Title {
    white-space: nowrap;
    color: colors.$title;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      padding: 0 0 0 0.25rem;
      margin-top: 0.6875rem;
    }

    @media only screen and (min-width: 640px) {
      margin: 0 0.25rem;
    }
  }

  &Content {
    display: none;
    color: colors.$text;
  }

  &.isExpanded {
    .legendTag {
      width: 100%;
      display: grid;
      grid-template-columns: 130px 1fr;
      margin-bottom: 1rem;
      @media only screen and (min-width: 992px) {
        grid-template-columns: 160px 1fr;
      }
    }

    .legendTitle {
      display: inline;
      text-align: left;
      margin: 0;

      span {
        margin: 0;
      }
    }

    .legendContent {
      display: inline;
    }
  }

  @media only screen and (orientation: portrait) {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }

  .infoText {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    padding: 0 2rem;
    img {
      margin-right: 1rem;
    }
  }
}
