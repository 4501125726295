@use "../assets/colors" as colors;

.chartNavigationInner {
  height: 3rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  position: relative;

  h3 {
    display: inline-block;
    margin: 0 0.5rem;
    font-size: 0.875rem;
    font-weight: normal;
    color: colors.$title;
    line-height: 1rem;
  }

  /* BUTTONS */
  .button {
    appearance: none;
    text-decoration: none;
    background-color: transparent;
    border: 0 none;
    height: 3rem;
    font-family: inherit;
    font-size: 0.875rem;
    color: colors.$title;
    line-height: 2rem;
    position: relative;
    overflow: hidden;
    padding: 0.5rem;
  }
  .buttonHiddenLabel {
    position: absolute;
    top: 200%;
  }
  .buttonClicker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .button:hover .buttoIcon {
    fill: colors.$text;
  }

  /* Modifiers */
  .button.buttonNext,
  .button.buttonPrevious {
    display: block;
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    transform: translateY(-1.5rem);
    position: absolute;
    top: 50%;
  }
  .button.buttonNext {
    right: 0;
  }
  .button.buttonPrevious {
    left: 0;
  }
  .weekViewNavigation {
    color: colors.$bright-blue;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
