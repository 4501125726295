@use "../../assets/colors" as colors;
@import "../../assets/responsive";
@import "../../assets/typography";

.signupAlreadyCompletedContainer {
  background-color: colors.$white;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6rem;

    @include md-down {
      padding: 2rem;
    }

    .left {
      img {
        height: 25rem;
        width: 25rem;

        @include md-down {
          width: 100%;
          height: auto;
        }
      }
    }

    .right {
      padding: 4rem 7rem;

      .title {
        @include heading_1;
        color: #4f9bd6;
        margin-bottom: 2rem;
      }

      @include md-down {
        padding: 4rem 1rem;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .content {
      flex-wrap: nowrap;
      .right {
        padding-right: 0;
      }
    }
  }
}
