$font_family_primary: Arial, Helvetica, sans-serif;
$font_family_secondary: Arial, Helvetica, sans-serif;

/* ---- HEADING ---- */
@mixin heading_1 {
  font-family: $font_family_primary;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
}

@mixin heading_2 {
  font-family: $font_family_primary;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

/* ---- TEXT ---- */
@mixin text_1 {
  font-family: $font_family_primary;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

@mixin text_2 {
  font-family: $font_family_secondary;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
