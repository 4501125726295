@use "../assets/colors" as colors;

.loading {
  padding: 1rem;
  background: colors.$blue;
  text-align: center;
  color: colors.$charcoalGrey;
}
.message {
  padding: 0.5rem 1rem;
  background: colors.$blue;
}
.message p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  text-align: left;
  color: colors.$white;
  margin: 0.5rem 0;
}
.error {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 1rem;
  background: colors.$orange;
  color: colors.$white;
}
.error a {
  color: colors.$white;
}
.features {
  display: flex;
  flex-wrap: wrap;
}
.feature {
  width: 100%;
  background: colors.$white;
}
@media only screen and (min-width: 640px) {
  .feature {
    width: 50%;
  }
}
.featureContent {
  padding: 1rem 1rem 1.5rem 1rem;
}
.feature img {
  width: 100%;
  max-width: 640px;
  height: auto;
  background: colors.$white;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: colors.$charcoalGrey;
}
.feature h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  color: colors.$charcoalGrey;
  margin: 0 0 8px 0;
  text-align: left;
}
.feature p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  color: colors.$grey;
  margin: 0;
  text-align: left;
}
