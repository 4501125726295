@use "../../assets/colors" as colors;
@import "../../assets/responsive";

.footer {
  width: 100%;
  height: 32.125rem;
  background-color: #f6f8f9;
  position: relative;
}
.logo {
  max-width: 40%;
  max-height: 40%;
  margin-bottom: 1.5rem !important;
}
@media only screen and (max-width: 768px) {
  .footer {
    a {
      margin-left: 0rem !important;
    }
  }
  .logo {
    max-width: 80%;
    max-height: 80%;
    left: 50%;
  }
}
.content {
  left: 15%;
  right: 15%;
  margin-top: 5.125rem;
  z-index: 1;
  position: absolute;
  p {
    font-size: 0.75rem;
    line-height: 0.9425rem;
    font-style: normal;
    font-weight: 300;
  }
}
.footerLinks {
  display: flex;
  margin-top: 2rem;
}
.footerLinks a {
  color: colors.$footer-link;
  font-size: 1rem;
  display: block;
  cursor: pointer;
  text-decoration-line: none;
}
.footerLinks span {
  color: colors.$footer-link;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.footerLinks a:hover {
  color: colors.$blue;
}
.footerBanner {
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: 0;

  img {
    height: 200px;

    @include sm-down {
      height: auto;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 992px) {
  .footer {
    height: 42.9375rem;
    p {
      font-size: 0.75rem;
      line-height: 0.9425rem;
      font-style: normal;
      font-weight: 300;
    }
  }
  .content {
    margin-top: 3.6875rem;
  }
}
@media only screen and (min-width: 992px) {
  .footer {
    height: 32.125rem;
    p {
      font-size: 0.75rem;
      line-height: 0.9425rem;
      font-style: normal;
      font-weight: 300;
    }
  }
  .content {
    margin-top: 5.125rem;
  }
}
