@use "../assets/colors" as colors;

.feedback {
  .pageContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 2.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    background-color: colors.$white;
  }

  .messageContact {
    padding: 4rem 0;

    .emailLink {
      color: colors.$footer-link;
    }
  }
}

.loading {
  padding: 1rem;
  text-align: center;
  color: colors.$charcoalGrey;
  font-size: 1.3rem;
}
