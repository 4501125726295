@import "./responsive";
@import "./typography";

.commonSelectButton {
  padding: 1.25rem 3rem;
  margin: 0.5rem;
  background: #ffffff;
  border: 2px solid #00aaff;
  border-radius: 100px;
  text-align: center;
  min-width: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include text_1;
  font-size: 18px;
  color: #007acd;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }

  @include sm-down {
    gap: 2rem;
  }

  &Selected {
    background: #e6f2fa;
    border: 2px solid #e6f2fa;
  }
}
