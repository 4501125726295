@use "../assets/colors" as colors;

.insights {
  &Inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: colors.$grey;
    font-size: 0.875rem;

    @media only screen and (max-width: 640px) and (orientation: landscape) {
      flex-wrap: nowrap;
    }
  }

  .insight {
    margin: 0.125rem;
  }

  .trend {
    position: relative;
    display: block;
    background-color: colors.$lightGrey;
    color: colors.$title;
    padding: 0.25rem 1rem 0.25rem 1.5rem;
    background-size: 0.75rem;
    background-position: 0.5rem 0.5rem;
    background-repeat: no-repeat;
    border-radius: 0.125rem;

    svg {
      fill: colors.$text;
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      overflow: visible;
    }
  }
}
